
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Homepage from './pages/Homepage';
import './style/output.css';
import Navbar from "./components/Navbar";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./ContextApi/AppContext";
import BookingModal from "./pages/Homepage/Components/BookingModal";
import Footer from "./components/Footer";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms-and_condition";
import NotFoundPage from "./pages/404";
import { Toaster } from "react-hot-toast";
import { LocalStorageService } from "./services/localstorageservice";
import useFetchData from "./customhooks/useFetchData";
import Reachus from "./pages/ReachUs";
import AccountDeletePage from "./pages/account-delete";
function App() {
  const { PopupState, setPopupState } = useContext(AuthContext)
  const { makeRequest } = useFetchData();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GuestToken()

    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // Adjust the delay as needed

    // Cleanup the timeout on unmount
    return () => clearTimeout(delay);
    // window.onload = () => {
    //   setIsLoading(false);
    // };
  }, [])

  const GuestToken = async () => {
    const accessToken = LocalStorageService.getItem('accessToken_serv');
    if (accessToken === null || accessToken === '') {
      const data = await makeRequest('/auth/guest-token', 'get', {})
      if (data) {

        LocalStorageService.setItem('accessToken_serv', data.data.accessToken)
        LocalStorageService.setItem('refreshToken_serv', data.data.refreshToken)
      }

    }

  }

  return (
    <>
      <Router>
        <Navbar modalHandler={setPopupState} />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-condition" element={<Terms />} />
          <Route path="/reach-us" element={<Reachus />} />
          <Route path="/account-delete" element={<AccountDeletePage />} />

          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <BookingModal state={PopupState} Handler={setPopupState} />
        <Toaster />
        <Footer />
        <Loader state={isLoading} />
      </Router>
    </>
  );
}

export default App;


const Loader = ({state}) => {
  return (
    <div className={`${state ? 'fixed' :'hidden'}  inset-0 z-40 bg-white flex justify-center items-center`}>
      <img width={300} height={300} src="/assets/gif/loader.gif" alt="loader" />
    </div>
  )
}