import React from 'react';

const AccountDeletePage = () => {
  const containerStyle = {
    padding: '20px',
    textAlign: 'center',
    backgroundImage: "url('/assets/images/Background.png')", 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: "'Poppins', sans-serif",
  };

  const headingStyle = {
    fontSize: '42px',
    fontWeight: '600',
    marginBottom: '20px',
    color: '#000000',
    fontFamily: "'Poppins', sans-serif",
  };

  const subcontentStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
    maxWidth: '1338px',
    margin: '0 auto',
    color: '#000000',
    fontFamily: "'Poppins', sans-serif",
  };

  const sectionStyle = {
    display: 'flex',
    flexDirection: 'row', 
    gap: '128px', 
    marginTop: '48px',
    width: '100%',
    justifyContent: 'center', 
    alignItems: 'center',
    flexWrap: 'wrap', // Enables wrapping of flex items
  };

  const imageContainerStyle = {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center',
    gap: '48px', 
  };

  const imageStyle = {
    width: '300px',
    height: '600px',
    borderRadius: '10px',
    objectFit: 'cover',
  };

  const titleStyle = {
    fontSize: '16px',
    fontWeight: '500',
    color: '#333',
    width: '322px',
    lineHeight: '24px',
    textAlign: 'center',
    wordBreak: 'break-word',
    fontFamily: "'Poppins', sans-serif",
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Account Delete</h1>
      <p style={subcontentStyle}>
        Deactivating your account will temporarily hide your profile and restrict access to your data and services. Your information will remain securely stored, and you won’t receive notifications during this time. This action is not permanent, and you can reactivate your account anytime by logging back in. All your data and preferences will be restored upon reactivation.
      </p>

      <div style={sectionStyle}>
        <div style={imageContainerStyle}>
          <div>
            <h3 style={titleStyle}>
              “Click” the profile option then go to the Dashboard
            </h3>
          </div>
          <div>
            <img src="/assets/images/Blue.png" alt="Image 1" style={imageStyle} />
          </div>
        </div>
        <div style={imageContainerStyle}>
          <div>
            <h3 style={titleStyle}>
              “Select” the Delete Account then a popup will open on the screen
            </h3>
          </div>
          <div>
            <img src="/assets/images/Blue-1.png" alt="Image 2" style={imageStyle} />
          </div>
        </div>
        <div style={imageContainerStyle}>
          <div>
            <h3 style={titleStyle}>
              Confirm “Yes” & Deactivate your Account
            </h3>
          </div>
          <div>
            <img src="/assets/images/Blue-2.png" alt="Image 3" style={imageStyle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletePage;
